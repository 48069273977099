var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"home"}},[_c('div',{staticClass:"topTitle"},[_vm._v("扫码上报")]),_c('el-form',{ref:"ruleForm",staticClass:"topForm",attrs:{"model":_vm.ruleForm,"rules":_vm.rules,"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"手机号","prop":"phone"}},[_c('el-input',{attrs:{"maxlength":"11"},model:{value:(_vm.ruleForm.phone),callback:function ($$v) {_vm.$set(_vm.ruleForm, "phone", $$v)},expression:"ruleForm.phone"}})],1)],1),_c('div',{staticStyle:{"margin":"0 0 20px 10px"}},[_vm._v("功能")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"item",on:{"click":_vm.sendFireAlarm}},[_vm._m(0),_vm._m(1)]),_c('div',{staticClass:"item",on:{"click":_vm.sendForHelp}},[_vm._m(2),_vm._m(3)]),_c('div',{staticClass:"item",on:{"click":_vm.toCrtView}},[_vm._m(4),_vm._m(5)]),_c('div',{staticClass:"item",on:{"click":_vm.toReportView}},[_vm._m(6),_vm._m(7)])]),_c('div',{staticStyle:{"margin":"10px 0 15px 10px"}},[_vm._v("公众号")]),_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" 扫描下方二维码,前往“微安宝”公众号, ")]),_c('div',{staticStyle:{"text-align":"center","margin-top":"8px"}},[_vm._v(" 实现一键报警功能。 ")]),_c('img',{attrs:{"src":_vm.img}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"iconfont icon-iotechhuojing fireIcon"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',[_vm._v("上报火警")]),_c('div',[_vm._v("安全第一")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"iconfont icon-iotechdunpai- dunpaiIcon"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',[_vm._v("紧急求助")]),_c('div',[_vm._v("和谐社会")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"iconfont icon-iotechyingjishusan runIcon"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',[_vm._v("疏散路线")]),_c('div',[_vm._v("紧急出口")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"iconfont icon-iotechshangbao protectIcon"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',[_vm._v("隐患上报")]),_c('div',[_vm._v("防患未然")])])
}]

export { render, staticRenderFns }