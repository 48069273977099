<template>
  <div id="home">
    <div class="topTitle">扫码上报</div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="topForm">
      <el-form-item label="手机号" prop="phone">
        <el-input v-model="ruleForm.phone" maxlength="11"></el-input>
      </el-form-item>
    </el-form>
    <div style="margin:0 0 20px 10px;">功能</div>
    <div class="content">
      <div class="item" @click="sendFireAlarm">
        <div class="icon">
          <i class="iconfont icon-iotechhuojing fireIcon"></i>
        </div>
        <div class="text">
          <div>上报火警</div>
          <div>安全第一</div>
        </div>
      </div>
      <div class="item" @click="sendForHelp">
        <div class="icon">
          <i class="iconfont icon-iotechdunpai- dunpaiIcon"></i>
        </div>
        <div class="text">
          <div>紧急求助</div>
          <div>和谐社会</div>
        </div>
      </div>
      <div class="item" @click="toCrtView">
        <div class="icon">
          <i class="iconfont icon-iotechyingjishusan runIcon"></i>
        </div>
        <div class="text">
          <div>疏散路线</div>
          <div>紧急出口</div>
        </div>
      </div>
      <div class="item" @click="toReportView">
        <div class="icon">
          <i class="iconfont icon-iotechshangbao protectIcon"></i>
        </div>
        <div class="text">
          <div>隐患上报</div>
          <div>防患未然</div>
        </div>
      </div>
    </div>
    <div style="margin:10px 0 15px 10px;">公众号</div>
    <div style="text-align:center;">
      扫描下方二维码,前往“微安宝”公众号,
    </div>
    <div style="text-align:center; margin-top:8px">
      实现一键报警功能。
    </div>
    <img :src="img" />
  </div>
</template>

<script>
import scanCodeAlarm from "../../api/manageApi/scanCodeAlarm";
import deviceInfoManagement from "../../api/manageApi/DeviceInfoManagement"
import BaseData from "@/assets/config/BaseData.js";
export default {
  data() {
    return {
      scanCodeId: '', //二维码id
      ruleForm: {
        phone: '',
      },
      rules: {
        phone: [
          { required: true, message: '请输入正确的手机号', pattern: /^1[3456789]\d{9}$/, trigger: 'blur' },
        ]
      },
      img: require('../../assets/images/微安宝.jpg'),
      location: '',
      crtDeviceInfo: {},
      baseUrl: BaseData.baseUrl,
      crtWidth: '', //crt图片宽度
      crtHeight: '', //crt图片高度
      isCrt: true,//是否添加crt点位
    }
  },
  created() {
    this.scanCodeId = this.$route.query.scanCodeId;
    this.getQrCodeInfo();
  },
  methods: {
    //获取该二维码设备crt图及点位信息
    getQrCodeInfo() {
      this.isCrt = true;
      scanCodeAlarm.getQrCodeInfo(this.scanCodeId).then((res) => {
        this.crtDeviceInfo = res.data.data.crtDeviceInfo;
        this.location = res.data.data.location;
        if (this.crtDeviceInfo == null || this.crtDeviceInfo == undefined) {
          this.isCrt = false;
        } else {
          var image = new Image();
          image.src = this.baseUrl + this.crtDeviceInfo.crtPictureUrl;
          this.crtWidth = image.width;
          this.crtHeight = image.height;
        }

      })
    },
    //上报火警
    sendFireAlarm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$confirm('确认上报火警吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            scanCodeAlarm.sendFireAlarm(this.scanCodeId, this.location, this.ruleForm.phone, "火警确认").then((res) => {
              if ("上报成功" == res.data.message) {
                // this.$message({
                //   type: 'success',
                //   message: res.data.message
                // })
                this.$alert('上报成功！点击【确认】按钮继续。', '上报成功！', {
                  confirmButtonText: '确定',
                  showClose: false,
                }).then(() => {
                  if (this.isCrt) {
                    this.$router.push({
                      name: "scanCodeCRT",
                      params: {
                        crtDeviceInfo: this.crtDeviceInfo,
                        crtWidth: this.crtWidth,
                        crtHeight: this.crtHeight,
                      },
                    });
                  }
                })
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.message
                })
              }
            })
          }).catch(() => {
            // this.$message({
            //   type: 'info',
            //   message: '操作已取消'
            // })
          })
        }
      })
    },

    //紧急求助
    sendForHelp() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$confirm('确认上报治安报警吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            const formData = new FormData();
            formData.append("scanCodeId", this.scanCodeId);
            formData.append("location", this.location);
            formData.append("phone", this.ruleForm.phone);
            formData.append("type", "治安报警");
            scanCodeAlarm.sendForHelp(formData).then((res) => {
              if ("上报成功" == res.data.message) {
                // this.$message({
                //   type: 'success',
                //   message: res.data.message
                // })
                this.$alert('上报成功！点击【确认】按钮继续。', '上报成功！', {
                  confirmButtonText: '确定',
                  showClose: false,
                })
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.message
                })
              }
            })
          }).catch(() => {

          })
        }
      })
    },

    //逃生路线
    toCrtView() {
      if (this.isCrt) {
        this.$router.push({
          name: "scanCodeCRT",
          params: {
            crtDeviceInfo: this.crtDeviceInfo,
            crtWidth: this.crtWidth,
            crtHeight: this.crtHeight,
            location: this.location,
          },
        });
      } else {
        this.$message({
          type: 'error',
          message: "该二维码设备未在平台添加CRT"
        })
      }

    },

    //隐患上报
    toReportView() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          //跳转到信息填写页面
          this.$router.push({
            name: "scanCodeReport",
            params: {
              scanCodeId: this.scanCodeId,
              phone: this.ruleForm.phone,
              location: this.location
            },
          });
        }
      })
    }

  }
}
</script>

<style socped lang="scss">
#home {
  background: #fff;
  width: 24.8%;
}
.topTitle {
  font-size: 18px;
  text-align: center;
  padding: 15px 0;
  margin-bottom: 15px;
  border-bottom: 1px solid rgb(146, 198, 240);
  box-shadow: 0 0 1px 0 3px rgb(144, 224, 235);
}
.topForm {
  padding: 8px 0 5px 0;
  width: 90%;
}
.fireIcon {
  color: red;
  font-size: 60px;
  margin: 0 0 20px 0;
}
.dunpaiIcon {
  color: rgb(93, 107, 182);
  font-size: 60px;
  margin: 0 0 20px 0;
}
.runIcon {
  color: rgb(92, 202, 70);
  font-size: 60px;
  margin: 0 0 20px 0;
}
.protectIcon {
  color: rgb(129, 75, 230);
  font-size: 60px;
  margin: 0 0 20px 0;
}
.content {
  box-sizing: border-box;
  width: 100%;
  height: 200px;
  //   border: 1px solid red;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .item {
    display: flex;
    width: 40%;
    height: 80px;
    border-radius: 10px;
    padding: 0 5px;
    margin: 0 5px;
    background: rgba(156, 219, 240, 0.3);
    .text {
      margin: 10px 0 0 5px;
      height: 30px;
      line-height: 30px;
      div:nth-child(1) {
        font-size: 17px;
      }
      div:nth-child(2) {
        color: #aaa;
        font-size: 13.5px;
      }
    }
  }
}
img {
  width: 200px;
  height: 200px;
  margin: 0 auto;
}
.el-message-box {
  width: 80%;
}
.el-message-box__btns {
  // 交换 确定 和 取消 按钮的位置
  .el-button:nth-child(1) {
    float: right;
    margin-right: 20%;
  }
  .el-button:nth-child(2) {
    float: left;
    margin-left: 25%;
  }
}
</style>